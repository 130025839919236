import image1 from '../images/confirmed/1.jpeg';
import image2 from '../images/confirmed/2.jpeg';
import image3 from '../images/confirmed/3.jpeg';
import image4 from '../images/confirmed/4.jpeg';
import image5 from '../images/confirmed/5.jpeg';
import image6 from '../images/confirmed/6.jpeg';


import i1 from '../images/common/1.jpeg';
import i2 from '../images/common/2.jpeg';
import i3 from '../images/common/3.jpeg';


export const confirmed = [
    image1, image2, image3, image4, image5, image6
];

export const common = [
    i1, i2, i3
];